import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { isArray, isEmpty } from "lodash";
import uniqid from "uniqid";
import { parseQS } from "../utils";
import { AnimatePresence, motion } from "framer-motion";

import ItemThumbnail from "../components/Work/ItemThumbnail/ItemThumbnail";
import ItemFilter from "../components/Work/ItemFilter/ItemFilter";

const propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

const pageVariants = {
	initial: {
		opacity: 0,
		y: -10,
	},
	in: (idx) => ({
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.75,
			ease: [0.25, 1, 0.5, 1],
			delay: idx * 0.1,
		},
	}),
	out: {
		opacity: 0,
		transition: {
			duration: 0.5,
			ease: [0.55, 0, 1, 0.45],
		},
	},
};

class Work extends PureComponent {
	state = {
		workList: [],
		filterList: [],
	};

	componentDidMount() {
		this.getFilters();
		this.filterWorkListData();
	}

	componentDidUpdate(prevProps) {
		const {
			location: { search },
		} = this.props;

		if (search !== prevProps.location.search) {
			this.filterWorkListData();
		}
	}

	getFilters() {
		const {
			data: {
				workData: { edges: workData },
			},
		} = this.props;

		const filterList = [];

		workData.forEach((workDataItem) => {
			const {
				node: {
					childMarkdownRemark: {
						frontmatter: { projectCategory: workDataItemCategory },
					},
				},
			} = workDataItem;

			if (isArray(workDataItemCategory) && !isEmpty(workDataItemCategory)) {
				workDataItemCategory.forEach((catItem) => {
					if (!filterList.includes(catItem.toLowerCase())) {
						filterList.push(catItem.toLowerCase());
					}
				});
			}
		});

		this.setState({
			filterList,
		});
	}

	filterWorkListData() {
		const {
			data: {
				orderedListData: {
					childMarkdownRemark: {
						frontmatter: { work_ordered_list: orderedList },
					},
				},
				workData: { edges: workData },
			},
			location: { search },
		} = this.props;
		const queryString = parseQS(search, "tag");

		const filteredList = [];

		orderedList.forEach((orderedItem) => {
			const matchingItem = workData.find((workItem) => {
				const {
					node: {
						childMarkdownRemark: {
							frontmatter: { projectTitle: workItemTitle, projectCategory: workItemCategory },
						},
					},
				} = workItem;

				// unfiltered search & push – only checks for matching titles
				if (queryString === undefined || queryString[0] === "all" || !queryString) {
					return orderedItem.work_item.toLowerCase() === workItemTitle.toLowerCase();
				}

				// filtered search & push – checks for matching titles,
				// AND queryString that is present in item's category obj
				if (
					orderedItem.work_item.toLowerCase() === workItemTitle.toLowerCase() &&
					!isEmpty(workItemCategory)
				) {
					return workItemCategory
						.map((c) => c.toLowerCase())
						.includes(queryString[0].toLowerCase());
				}

				return null;
			});

			if (matchingItem) {
				filteredList.push({
					name: matchingItem.node.name,
					slug: matchingItem.node.childMarkdownRemark.fields.slug,
					...matchingItem.node.childMarkdownRemark.frontmatter,
				});
			}
		});

		this.setState({
			workList: filteredList,
		});
	}

	render() {
		const { workList, filterList } = this.state;
		const {
			location: { search },
		} = this.props;

		return (
			<>
				<ItemFilter
					data={filterList}
					activeQuery={parseQS(search, "tag") ? parseQS(search, "tag")[0] : ""}
				/>

				{isArray(workList) && !isEmpty(workList) && (
					<section className="clearfix" style={{ maxWidth: "1366px", margin: "0 auto" }}>
						{workList.map((item, idx) => {
							return (
								<AnimatePresence exitBeforeEnter>
									<motion.div
										initial={pageVariants.initial}
										animate={pageVariants.in(idx)}
										exit={pageVariants.out}
										key={search}
									>
										<ItemThumbnail
											key={uniqid()}
											slug={item.slug}
											heroImage={item.projectHeroImage}
											category={item.projectCategory}
											title={item.projectTitle}
											description={item.projectDescription}
											clientCompany={item.projectClientCompany}
										/>
									</motion.div>
								</AnimatePresence>
							);
						})}
					</section>
				)}
			</>
		);
	}
}

Work.propTypes = propTypes;
export default Work;

export const query = graphql`
	query {
		orderedListData: file(name: { eq: "work-sort_order" }) {
			id
			childMarkdownRemark {
				frontmatter {
					work_ordered_list {
						work_item
					}
				}
			}
		}

		workData: allFile(filter: { relativeDirectory: { eq: "work" } }) {
			edges {
				node {
					id
					name
					childMarkdownRemark {
						id
						frontmatter {
							projectDescription
							projectClientCompany
							projectTitle
							projectHeroImage
							projectCategory
						}
						fields {
							slug
						}
					}
				}
			}
		}
	}
`;

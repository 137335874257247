import React from "react";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import { navigate } from "gatsby";
import uniqid from "uniqid";

import styles from "./ItemFilter.module.scss";

const propTypes = {
	data: PropTypes.array,
	activeQuery: PropTypes.string,
};

const defaultProps = {
	data: null,
	activeQuery: "",
};

function ItemFilter({ data, activeQuery }) {
	if (!isArray(data) || isEmpty(data)) return null;

	return (
		<section className={styles.section__mainWrapper}>
			<p className={styles.section__title}>Filter</p>

			{isArray(data) && !isEmpty(data) && (
				<section className={styles.filter__wrapper}>
					<button
						key={uniqid()}
						className={`${styles.filter__item} ${
							activeQuery === "all" || activeQuery === "" ? styles["filter__item--active"] : ""
						}`}
						onClick={() => navigate(`/work?tag=all`)}
						type="button"
					>
						All
					</button>

					{data.map((item) => (
						<button
							key={uniqid()}
							className={`${styles.filter__item} ${
								activeQuery === item ? styles["filter__item--active"] : ""
							}`}
							onClick={() => navigate(`/work?tag=${item.toLowerCase()}`)}
							type="button"
						>
							{item}
						</button>
					))}
				</section>
			)}
		</section>
	);
}

ItemFilter.propTypes = propTypes;
ItemFilter.defaultProps = defaultProps;

export default ItemFilter;

import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import uniqid from "uniqid";
import { truncateStr } from "../../../utils";

import styles from "./ItemThumbnail.module.scss";

const propTypes = {
	slug: PropTypes.string,
	category: PropTypes.array,
	heroImage: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	clientCompany: PropTypes.string,
};

const defaultProps = {
	slug: null,
	category: [],
	heroImage: null,
	title: "",
	description: "",
	clientCompany: "",
};

function ItemThumbnail({ slug, category, heroImage, title, description, clientCompany }) {
	return (
		<Link to={slug} className={styles.wrapper__main}>
			<img src={heroImage} alt={title} className={styles.content__image} />
			<div className={styles.wrapper__copyMain}>
				<div className={styles.wrapper__copyContent}>
					{isArray(category) && !isEmpty(category) && (
						<div className={styles.content__categoryWrapper}>
							{category.map((item, idx) => (
								<React.Fragment key={uniqid()}>
									<span className={styles.content__categoryItem}>{item}</span>
									{idx < category.length - 1 ? (
										<span className={styles.content__categoryItem}> / </span>
									) : null}
								</React.Fragment>
							))}
						</div>
					)}
					<h1 className={styles.content__title}>{title}</h1>
					<h2 className={styles.content__description}>{truncateStr(description, 200)}</h2>
					<p className={styles.content__clientCompany}>{clientCompany}</p>
				</div>
			</div>
		</Link>
	);
}

ItemThumbnail.propTypes = propTypes;
ItemThumbnail.defaultProps = defaultProps;

export default ItemThumbnail;
